import { require } from '././../../utils/http'
//注册管理
function day_statistics(obj) {
  return require('post', '/api/kapin_ee_admin/auth/v1/day_statistics/', obj)
}
function home_page(obj) {
  return require('post', '/api/kapin_ee_admin/auth/v1/home_page/', obj)
}
function month_all_count() {
  return require('post', '/api/kapin_ee_admin/auth/v1/month_all_count/', {})
}
function mon_all_elite() {
  return require('post', '/api/kapin_ee_admin/auth/v1/month_all_count_elite/', {})
}
function day_detail(obj) {
  return require('post', '/api/kapin_ee_admin/auth/v1/day_detail/', obj)
}

function mon_day_dee(obj) {
  return require('post', '/api/kapin_ee_admin/auth/v1/month_day_detail_elite/', obj)
}
function mon_day_de(obj) {
  return require('post', '/api/kapin_ee_admin/auth/v1/month_day_detail/', obj)
}
function resume_city_count(obj) {
  return require('post', '/api/kapin_ee_admin/auth/v1/resume_city_count/', obj)
}
function resume_city_detail(obj) {
  return require('post', '/api/kapin_ee_admin/auth/v1/resume_city_detail/', obj)
}

export {
  day_statistics,
  home_page,
  month_all_count,
  mon_all_elite,
  day_detail,
  mon_day_dee,
  mon_day_de,
  resume_city_count,
  resume_city_detail,
}
