import { require } from './../../utils/http'
//我的首页
function setMyHome() {
  return require('post', '/api/kapin_ee/company/v1/main/')
}
//设置公司展示
function setShowApi(data) {
  let obj = { update_data: data }

  console.log(data)

  return require('post', '/api/kapin_ee/company/v1/company_detail_update/', obj)
}

//展示城市数据
function showCity(data) {
  return require('post', '/api/public/v1/show_place_json/', data)
}
//风云人物
function setSignApi(data) {
  let obj = { sign_list: data }
  return require('post', '/api/kapin_ee/company/v1/company_sign_update/', obj)
}
//公司相册管理
function setImgApi(data) {
  let obj = { img_list: data }

  return require('post', '/api/kapin_ee/company/v1/company_img_update/', obj)
}
//经纬度
function getLocation(data) {
  return require('post', '/api/kapin_ee/company/v1/site_location/', data)
}
export { setShowApi, setSignApi, setImgApi, setMyHome, getLocation, showCity }
