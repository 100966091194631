<template>
  <div class="wrap">
    <div>
      <el-button v-if="sliter1" style="margin-bottom:10px;" @click="sliter">筛选</el-button>
      <el-dialog width="25%" title="筛选" :visible.sync="dialogFormVisible1">
        <el-form label-width="120px">
          <el-form-item label="帐号">
            <el-input v-model="form.username" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible1 = false">取 消</el-button>
          <el-button type="primary" @click="filtersu(0)">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog width="25%" title="筛选" :visible.sync="dialogFormVisible2">
        <el-form label-width="120px">
          <el-form-item label="公司所在区">
            <el-cascader placeholder="请选择公司所在地" v-model="city_name" :options="city_options" :props="{ value: 'label' }"
              clearable></el-cascader>
          </el-form-item>
          <el-form-item label="帐号">
            <el-input v-model="form.username" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="公司名称">
            <el-input v-model="form.license_company_name" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible2 = false">取 消</el-button>
          <el-button type="primary" @click="filtersu(0)">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog width="27%" title="筛选" :visible.sync="dialogFormVisible3">
        <el-form label-width="120px">
          <el-form-item label="姓名">
            <el-input v-model="form.name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="性别">
            <el-input v-model="form.gender" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="手机">
            <el-input v-model="form.phone" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="学历">
            <el-select v-model="form.education_background" placeholder="请选择">
              <el-option v-for="(item, index) in edu_list" :key="index" :value="item.label" :label="item.label">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="期望职位">
            <el-input v-model="form.position_id" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="danger" @click="clear">清 空</el-button>
          <el-button @click="dialogFormVisible3 = false">取 消</el-button>
          <el-button type="primary" @click="filtersu(0)">确 定</el-button>
        </div>
      </el-dialog>

      <el-table v-loading="loading" :data="tableData" style="width: 100%;min-height:68vh;" @row-click="goDetail">
        <el-table-column v-for="(item, index) in table_list" :key="index" :prop="item.field" :label="item.name">
        </el-table-column>
      </el-table>

      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :total="total"
        :page-size="pagesize" :current-page="currentPage1"></el-pagination>
    </div>
  </div>
</template>

<script>
import { day_detail, resume_city_detail } from '@/api/hr24_api/data_board.js'
import { showCity } from '@/api/me/index'
export default {
  data() {
    return {
      formLabelWidth: '120px',

      form: {
        username: '',
        license_company_name: '',
        position_id: '',
        name: '',
        gender: '',
        phone: '',
        education_background: '',
      },
      city_name: [],
      edu_list: [
        { label: '初中' },
        { label: '高中' },
        { label: '大专' },
        { label: '本科' },
        { label: '硕士' },
      ],
      pagesize: 15,
      currentPage1: 1,
      tableData: [],
      table_list: [],
      loading: true,
      dialogFormVisible1: false,
      dialogFormVisible2: false,
      dialogFormVisible3: false,
      sliter1: false,
      fliter_com: [
        'all_company',
        'pass_due_company',
        'use_company',
        'experience_user',
        'seed_user',
        'pay_user',
      ],
      fliter_eli: [
        'big_resume_count',
        'small_resume_count',
        'download_resume',
        'send_resume',
        'create_jd',
        'radar_list',
      ],
      total: 0,
      city_options: [],
    }
  },
  mounted() {
    let all = [...this.fliter_com, ...this.fliter_eli]
    if (
      all.indexOf(this.$route.query.src) < 0 &&
      !Number(this.$route.query.num)
    ) {
      this.sliter1 = false
    } else {
      this.sliter1 = true
    }
    let obj = {
      page: 0,
      show_data: this.$route.query.src,
    }
    day_detail(obj).then((res) => {
      this.loading = false
      if (!res.code) {
        this.tableData = res.data.return_data
        this.table_list = res.data.title
        this.total = res.data.all_count
      } else {
        this.$message.error(res.msg)
      }
    })
  },

  created() {
    showCity({}).then((res) => {
      console.log(res)
     // this.city_options = [{ label: '唐山市', value: '130200', children: [] }]

     this.city_options =res.data;
      // res.data[0].children.map((item, index) => {
      //   if (index != 0) {
      //     this.city_options[0].children.push(item)
      //   }
      // })
    })
  },

  methods: {
    sliter() {
      if (Number(this.$route.query.num)) {
        this.dialogFormVisible3 = true
      } else {
        if (this.fliter_eli.indexOf(this.$route.query.src) >= 0) {
          this.dialogFormVisible1 = true
        } else if (this.fliter_com.indexOf(this.$route.query.src) >= 0) {
          this.dialogFormVisible2 = true
        }
      }
    },
    filtersu(page = 0) {
      console.log(page)
      this.loading_show = true
      let search_data = {}
      for (let i in this.form) {
        if (this.form[i] != '') {
          search_data[i] = this.form[i]
        }
      }
      console.log(this.city_name)
      if (this.city_name.length != 0) {
        search_data.city_name = this.city_name[2]
      }
      this.currentPage1 = page + 1

      let obj = { page, search_data, show_data: this.$route.query.src }
      day_detail(obj).then((res) => {
        console.log(res)
        if (!res.code) {
          this.loading_show = false
          this.tableData = res.data.return_data
          this.table_list = res.data.title
          this.total = res.data.all_count
          this.dialogFormVisible1 = false
          this.dialogFormVisible2 = false
          this.dialogFormVisible3 = false
        } else {
          this.$message.warning(res.msg)
        }
      })
    },

    clear() {
      this.dialogFormVisible3 = false
    },
    handleCurrentChange(val) {
      this.page = val - 1
      this.currentPage1 = val
      let index = 0
      for (let i in this.form) {
        if (this.form[i] != '') {
          index++
        }
      }
      if (this.city_name.length > 0) {
        index++
      }
      if (index > 0) {
        this.currentPage1 = 1
        this.filtersu(val - 1)
      } else {
        this.currentPage1 = 1
        let obj = {
          page: val - 1,
          show_data: this.$route.query.src,
        }
        day_detail(obj).then((res) => {
          this.loading = false
          if (!res.code) {
            this.tableData = res.data.return_data
            this.table_list = res.data.title
            this.total = res.data.all_count
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    },
    handleSizeChange(val) {
      this.currentPage1 = val
    },

    //跳转到简历页面
    goDetail(row, column, event) {
      console.log("sssssssss");
      console.log(row);
      if (row.resume_id) {
        this.$router.push({
          path: "/resumedetails",
          query: {
            id: row.resume_id,
          },
        });
      }
    },
  },
}
</script>

<style scoped>
.wrap {
  padding-top: 54px;
}
.wrap > div:first-child {
  min-height: 77vh;
}

.el-pagination {
  margin-top: 10px;
  text-align: center;
}

.el-input,
.el-select {
  min-width: 30px;
  width: 80%;
}
</style>
